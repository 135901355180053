'use client'

import { css, createGlobalStyle } from 'styled-components'

import { primaryFont } from '@/theme/fonts'

const reset = css`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  /* Prevent font size inflation */
  html {
    -moz-text-size-adjust: none;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
  }

  /* Remove default margin in favour of better control in authored CSS */
  body, h1, h2, h3, h4, h5, h6, p,
  figure, blockquote, dl, dd {
    margin: 0;
  }

  /* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
  ul[role='list'],
  ol[role='list'] {
    list-style: none;
  }

  /* Set core body defaults */
  body {
    min-height: 100svh;
    line-height: 1.5;
  }

  /* Set shorter line heights on headings and interactive elements */
  h1, h2, h3, h4, h5, h6,
  button, input, label {
    line-height: 1.1;
  }

  /* Balance text wrapping on headings */
  h1, h2,
  h3, h4,
  h5, h6 {
    text-wrap: balance;
  }

  /* A elements that don't have a class get default styles */
  a:not([class]) {
    text-decoration-skip-ink: auto;
    color: currentColor;
  }

  /* Make images easier to work with */
  img,
  picture {
    max-width: 100%;
    display: block;
  }

  /* Inherit fonts for inputs and buttons */
  input, button,
  textarea, select {
    font: inherit;
  }

  /* Make sure textareas without a rows attribute are not tiny */
  textarea:not([rows]) {
    min-height: 10em;
  }

  /* Anything that has been anchored to should have extra scroll margin */
  :target {
    scroll-margin-block: 5ex;
  }
`

const GlobalStyles = createGlobalStyle`
  ${reset}

  body {
    ${primaryFont.style}

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0.3;
      background: url(images/noise.webp);
      animation: 90ms infinite noise;
      -webkit-animation: 90ms infinite noise;
      pointer-events: none;
      z-index: 999;
    }

    @keyframes noise {
      0%,
      100% {
          background-position:0 0
      }
      10% {
          background-position:-5% -10%
      }
      20% {
          background-position:-15% 5%
      }
      30% {
          background-position:7% -25%
      }
      40% {
          background-position:20% 25%
      }
      50% {
          background-position:-25% 10%
      }
      60% {
          background-position:15% 5%
      }
      70% {
          background-position:0 15%
      }
      80% {
          background-position:25% 35%
      }
      90% {
          background-position:-10% 10%
      }
    }
  }
`

export default GlobalStyles
